import { render, staticRenderFns } from "./catalog-item-buttons.vue?vue&type=template&id=d416b08e"
import script from "./catalog-item-buttons.vue?vue&type=script&lang=js"
export * from "./catalog-item-buttons.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonAutoteka: require('/home/d/didisher2/autodrive.spb.ru/www/components/button/button-autoteka.vue').default,ButtonTypical: require('/home/d/didisher2/autodrive.spb.ru/www/components/button/button-typical.vue').default,ButtonCall: require('/home/d/didisher2/autodrive.spb.ru/www/components/button/button-call.vue').default,ButtonFavorite: require('/home/d/didisher2/autodrive.spb.ru/www/components/button/button-favorite.vue').default,ButtonAutotekaListCar: require('/home/d/didisher2/autodrive.spb.ru/www/components/button/button-autoteka-list-car.vue').default})
