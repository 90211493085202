
import {mapActions, mapGetters, mapMutations} from "vuex";
import ButtonCompare from "@/components/button/button-compare";
import ButtonAutoteka from "@/components/button/button-autoteka";
// import cardClick from "~/mixins/cardClick";
import filters from "@/mixins/filters";
import isNy from "~/mixins/isNy";

export default {
	mixins: [filters, isNy],
	components: {ButtonAutoteka, ButtonCompare},
	computed: {
		...mapGetters({
			likesArray: 'favorite/favorite/likesArray',
			currentCar: 'modal/modal-choose/currentCar',
			settings: 'settings/settings/settings'
		}),
		creditPrice() {
			let percent = Number(this.settings.credit_percent.split('%')[0]);
			let n = 96;
			let coef_i = percent / 12 / 100;
			let K = (coef_i * Math.pow(1 + coef_i, n)) / (Math.pow(1 + coef_i, n) - 1);
			return new Intl.NumberFormat("ru-RU").format(Math.round(K * this.offer.price)) + ' ₽/мес.';
		},
		isEqual(){
			if(this.currentCar){
				return this.currentCar.id === this.offer.id
			} else{
				return false
			}
		}
	},
	props: {
		isForm: {
			type: Boolean,
			default: false
		},
		long: {
			type: Boolean,
			default: false
		},
		offer: {
			type: Object,
			default: () => {
			}
		},
		url:{
			type: String,
			default: ''
		},
		choose: {
			type: Boolean,
			default: false
		},
	},
	methods: {
		...mapActions({
			liked: 'favorite/favorite/liked',
			openModal: 'modal/modal-main/openModal',
			closeModal: 'modal/modal-main/closeModal',
		}),
		...mapMutations({
			setCurrentCar: 'modal/modal-choose/SET_CURRENT_CAR'
		}),
		async chooseClick(offer) {
			this.setCurrentCar(offer)
			await this.closeModal()
		},
		async like() {
			await this.liked(this.offer.external_id)
		},
		async call() {
			let payload = {
				modal_data: this.offer,
				modal_component: 'modal-callback',
				modal_title: 'Обратный звонок',
				modal_sub_title: this.offer.name
			}
			await this.openModal(payload)
		},
		callPhone(){
			let date = new Date().getHours();
			if(date >= 21 || date < 9){
				let payload = {
				modal_data: this.offer,
				modal_component: 'modal-callback',
				modal_title: 'Обратный звонок',
				modal_sub_title: 'Мы работаем ' + this.settings.work_schedule.replace('Ежедневно ','') + '. Оставьте заявку, и мы свяжемся с вами в рабочее время!'
				}
				this.openModal(payload)
			}else{
				var myLink = document.createElement('a');
           		myLink.setAttribute('href','tel:'+this.settings.phone.replace(/[^+\d]/g, ''));
           		myLink.click();
			}
		},
		async tradeIn(carInfo) {
			let payload = {
				modal_data: carInfo,
				modal_component: 'modal-tradeIn',
				modal_title: 'Заявка на Trade-In',
				modal_sub_title: carInfo.name
			}
			await this.openModal(payload)
		},
		autoteka(carInfo) {
			let payload = {
				modal_data: carInfo,
				modal_component: 'modal-autoteka',
				modal_title: 'Отчет «Автотеки» от ' + carInfo.createdAt,
				modal_sub_title: carInfo.name
			}
			this.openModal(payload)
		},
		async credit() {
			let payload = {
				modal_data: this.offer,
				modal_component: 'modal-credit',
				modal_title: 'Заявка на автокредит',
				modal_sub_title: this.offer.name
			}
			await this.openModal(payload)
		}
		// toCatalog() {
		// 	this.$router.push('/used')
		// }
	}
}
